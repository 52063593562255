import { Builder } from '@builder.io/react'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFeatureValue } from '@growthbook/growthbook-react'
import React, { useEffect } from 'react'
import LinkRoute from '../../ui/link/Link'
import LinkButton, { LinkButtonProp, LinkButtonRegisterProps } from '../common/button'

enum Position {
  Bottom = 'bottom',
  Top = 'top'
}
interface Props {
  bannerTopic: string
  bannerTopicLong: string
  backgroundColor: string
  fontColor: string
  icon: string
  stickyBottom: boolean
  bannerPosition: Position
  buttonProps: LinkButtonProp
  centered: boolean
}

const StickyWrapper = ({ children, position }: { children: any; position: Position }) => {
  const bannerPositionClass = position === Position.Top ? 'top-0' : 'bottom-0'

  return <div className={`fixed inset-x-0 ${bannerPositionClass}`}>{children}</div>
}

const InnerContent = ({ buttonProps, centered, bannerTopic, bannerTopicLong, fontColor }: any) => {
  if (centered) {
    return (
      <>
        <div className="flex items-center justify-center sm:px-16">
          <p className="text-sm font-medium sm:text-base md:mr-5 md:mb-0 " style={{ color: fontColor }}>
            <span className="md:hidden">
              <LinkRoute href={buttonProps.buttonUrl} className="text-white">
                {bannerTopic}
              </LinkRoute>
            </span>
            <span className="hidden md:inline">{bannerTopicLong}</span>
          </p>
          <div className="hidden pl-4 sm:block">
            <LinkButton
              buttonColor={buttonProps.buttonColor}
              buttonText={buttonProps.buttonText}
              loggedInButtonText={buttonProps.loggedInButtonText}
              buttonTextColor={buttonProps.buttonTextColor}
              buttonUrl={buttonProps.buttonUrl}
              isLink={buttonProps.isLink}
              onClick={() => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'cta_button_click', {
                    page: 'homepage'
                  })
                }
              }}
            />
          </div>
        </div>
        {/* <div className="absolute inset-y-0 right-0 flex items-center pr-1 sm:items-center sm:pr-2">
          <button type="button" className="flex rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-white">
            <span className="sr-only">Dismiss</span>
            <FontAwesomeIcon icon={faX} className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div> */}
      </>
    )
  }

  return (
    <div className="flex flex-wrap items-center justify-between">
      <div className="flex w-0 flex-1 items-center justify-center sm:justify-start">
        <p
          className="ml-3 truncate text-center text-sm font-medium sm:text-left sm:text-base"
          style={{ color: fontColor }}
        >
          <span className="md:hidden">
            <LinkRoute href={buttonProps.buttonUrl} className="text-white">
              {bannerTopic}
            </LinkRoute>
          </span>
          <span className="hidden md:inline">{bannerTopicLong}</span>
        </p>
      </div>
      <div className="order-3 mt-2 hidden w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:block sm:w-auto">
        <LinkButton
          buttonColor={buttonProps.buttonColor}
          buttonText={buttonProps.buttonText}
          loggedInButtonText={buttonProps.loggedInButtonText}
          buttonTextColor={buttonProps.buttonTextColor}
          buttonUrl={buttonProps.buttonUrl}
          isLink={buttonProps.isLink}
          onClick={() => {
            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', 'cta_button_click', {
                page: 'homepage'
              })
            }
          }}
        />
      </div>
      <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
        {/* <button
          type="button"
          className="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-6 w-6 " aria-hidden="true" />
        </button> */}
      </div>
    </div>
  )
}

const Content = ({ bannerTopic, bannerTopicLong, buttonProps, centered, backgroundColor, fontColor }: any) => {
  return (
    <div
      className="relative bg-indigo-600"
      style={{ backgroundColor: backgroundColor, color: fontColor, zIndex: '999' }}
    >
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <InnerContent
          bannerTopic={bannerTopic}
          bannerTopicLong={bannerTopicLong}
          buttonProps={buttonProps}
          centered={centered}
          fontColor={fontColor}
        />
      </div>
    </div>
  )
}

const Banner = ({
  bannerTopic,
  bannerTopicLong,
  stickyBottom,
  buttonProps,
  centered,
  backgroundColor,
  fontColor,
  bannerPosition
}: Props) => {
  const handleBtnText = () => {
    if (window.innerWidth < 400) {
      if (buttonProps?.buttonText) buttonProps.buttonText = 'Get Started'
    } else {
      if (buttonProps?.buttonText) buttonProps.buttonText = 'Create Plan'
    }
  }

  useEffect(() => {
    handleBtnText()
    window.addEventListener('resize', handleBtnText)

    return () => {
      window.removeEventListener('resize', handleBtnText)
    }
  }, [])

  if (stickyBottom) {
    return (
      <StickyWrapper position={bannerPosition}>
        <Content
          bannerTopic={bannerTopic}
          bannerTopicLong={bannerTopicLong}
          buttonProps={buttonProps}
          centered={centered}
          backgroundColor={backgroundColor}
          fontColor={fontColor}
        />
      </StickyWrapper>
    )
  }

  return (
    <Content
      bannerTopic={bannerTopic}
      bannerTopicLong={bannerTopicLong}
      buttonProps={buttonProps}
      centered={centered}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    />
  )
}

export default Banner
